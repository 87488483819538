import axios from 'axios'
// import router from '../router/index'
import { Message } from 'element-ui'


axios.interceptors.request.use(config => {
    if(localStorage.getItem('authorization')){
        config.headers.Authorization = localStorage.getItem('authorization')
    }
    return config
})
/**
* @Desc 配置响应参数
*/
axios.interceptors.response.use(
    response => {
        // console.log('response===>',response)
        let auth = response?.headers?.authorization
        if(auth) localStorage.setItem('authorization', auth);

        // 当状态码是200时进入resolve状态,否则进入reject
        if (response.status === 200) {
            // code 非200时则代表出错,抛出错误提示
            if(response.data.code !== 200){

                console.log(1)
                if(!window.isMobile){
                    Message({
                        showClose: true,
                        message: response.data.message,
                        type: 'error',
                        duration:5000,
                    });
                }else{
                    Message({
                        showClose: true,
                        message: response.data.message,
                        type: 'error',
                        duration:5000,
                        customClass:'myPhoneMsg'
                    });
                }
                return Promise.reject(response);
            }
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        console.log('response error===>',error)
        if(!window.isMobile){
            Message({
                showClose: true,
                message: "系统异常，请联系管理员",
                type: 'error',
                duration:5000,
            });
        }else{
            Message({
                showClose: true,
                message: '系统异常，请联系管理员',
                type: 'error',
                duration:5000,
                customClass:'myPhoneMsg'
            });
        }
        //return Promise.reject(error.response);
    }
);

/**
* @desc 封装请求,主要解决一些没必要的代码,例如判断接口是否成功,当接口已经成功访问到了后台,
* 但是后台报错的话,catch的error对象会有isRequestSuccess参数,值为200,当后台报错需要重置数据的话可以在catch回调里面完成
* @param { String } url - 接口路径
* @param { String } method - 具体的请求类型
* @param { Object } params - 请求的参数
*/
export const request = (url,method,params)=>{
    const data = {
        url:url,
        method,
        headers:{
            'Content-Type':'multipart/form-data;charset=UTF-8',
        }
    }

    if(method === 'get'){
        data.params = params
    }else{
        data.data = params
    }
    return new Promise((resolve,reject) => {
        axios(data).then(res => {
            if(res.code !== 200){
                return reject({isRequestSuccess:200,...res})
            }
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export default axios;
