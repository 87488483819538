<template>
    <div>
        <mobile v-if="isMobile" ></mobile>
        <pc v-else></pc>
    </div>
</template>
<script>
import pc from './pc.vue'
import mobile from './mobile.vue'
export default{
    name:'login',
    data(){
        return {
            isMobile:false,
        }
    },
    components:{
        pc,
        mobile,
    },
    created () {
        if (this.$route.query.code !== undefined) {
            sessionStorage.setItem('Code', this.$route.query.code)
        }
    },
    mounted(){
        if(this.isMobileFunc() && window.screen.width<550){
            this.isMobile=true;
        }else{
            this.isMobile=false;
        }
        window.isMobile=this.isMobile;
    },
    methods:{
        isMobileFunc(){
            let flag = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
