import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/element_theme/index.css';
import '@/assets/ali_icon/iconfont.css'
import './style/base.css';
import '@/style/public.scss'
import {Button,Dialog,Menu,Submenu,MenuItem,Input,Radio,Select,Table,Form,FormItem,Image,Message,Loading,MessageBox,Checkbox,Statistic} from 'element-ui';
Vue.use(Button).use(Dialog).use(Menu).use(Submenu).use(MenuItem)
.use(Input).use(Radio).use(Select).use(Form).use(FormItem)
.use(Table).use(Image).use(Checkbox).use(Statistic);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
