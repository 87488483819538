import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@views/index/home.vue'
import Login from '@views/user/login/index.vue'
//import Layout from '@views/layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '用户登录',
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '用户登录',
    },
  },
  {
    path: '/login', //登录
    name: 'login',
    component: Login,
    meta: {
      title: '用户登录',
    },
  },
  {
    path: '/register', //注册
    name: 'register',
    component: () => import('@/views/user/register/index.vue'),
    meta: {
      title: '用户注册',
    },
  },
  {
    path: '/resetpwd', //重置密码
    name: 'resetpwd',
    component: () => import('@/views/user/resetpwd/index.vue'),
    meta: {
      title: '重置密码',
    },
  },
  {
    path: '/multistore', //多个店铺
    name: 'multistore',
    component: () => import('@/views/user/multistore/index.vue'),
    meta: {
      title: '用户登录',
    },
  },
]

const router = new VueRouter({
  routes,
  //base: '/member/',
  //mode: 'history',
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '用户登录'
  next()
})

export default router
